import {list} from '@/domain/marketing/services/lista'

const searchLista = function () {
  console.log('click window search...')
  let window = 'windowMktListaSearch'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Buscar Lista Salva',
    width: '800',
    height: '600',
    minHeight: '500',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden bg-white',
    props: {
      columns: [
        {label: 'ID', value: 'id'},
        {label: 'Nome', value: 'nome'},
        {label: 'Inscritos', value: 'totalInscritos'},
      ],
      fetchData: (terms, filters, done) => {
        let rowsPerPage = 100
        list(rowsPerPage, filters.page, '&sortBy=id&descending=desc&search=' + terms)
          .then(({data}) => {
            let filteredData = data.result

            done(filteredData, {
              page: filters.page,
              rowsNumber: data.total, // response.totalRows,
              rowsPerPage: rowsPerPage
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, () => import('../../../layout/window/WindowSearch.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        selected: (wid, val) => {
          console.log('Selecionado com sucesso: ', val)
          this.findedData = val
          this.model = val.id
          this.__emit(val.id)
          this.description = val.id + ' - ' + val.nome
          setTimeout(() => {
            this.$refs.input && this.$refs.input.focus()
          }, 200)
        }
      })
      console.log(wid)
    }) // return wid
}

export default searchLista
