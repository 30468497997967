<template>
  <transition name="fade" appear>
    <div class="transition-generic w-full wrapper-lg m-t-n-lg">
      <campanha-icone class="big-white m-b" :canal="$route.params.canal" />
      <div class="wrapper">
        <e-row mr>
          <e-col>
            <erp-s-field label="Nome da campanha">
              <div class="font-bold">{{campanha.nome}}</div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Assunto">
              <div class="font-bold">{{campanha.assunto}}</div>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Iniciar em">
              <div class="font-bold">{{campanha.dataInicio}}</div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Publicado?">
              <sl-checkbox v-model="campanha.active" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Enviar para">
              <div class="font-bold">{{getTipoLabel(campanha.tipoString)}}</div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Layout">
              <div v-if="!campanhaContainer.templateManual" class="font-bold">{{campanha.templateRelacionado.id}} - {{campanha.templateRelacionado.name}}</div>
              <div class="font-bold" v-else>Novo</div>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
  </transition>
</template>

<script>
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import CampanhaMixin from "components/marketing/components/campanhaMixin"
import CampanhaIcone from "components/marketing/components/CampanhaIcone"
import SlCheckbox from "@/reuse/input/Checkbox"
export default {
  name: "CampanhaConfirmar",
  mixins: [CampanhaMixin],
  computed: {
  },
  components: {
    SlCheckbox,
    CampanhaIcone,
    // ErpInput,
    ErpSField,
    ECol,
    ERow
  }
}
</script>
