<template>
  <transition name="fade" appear>
    <div class="transition-generic w-full m-t-n-lg wrapper-lg">
      <campanha-icone class="big-white" :canal="$route.params.canal" />
      <h4 class="m-t-md">Para qual lista devemos enviar?</h4>
      <div class="m-t-lg">
        <m-radio name="tipoLista" label="Todas as pessoas registradas" val="todos" v-model="campanha.tipoString" />
        <div v-if="campanha.tipoString === 'todos'" class="wrapper m-t-n">
          <m-checkbox :value="1" v-model="campanha.config.pessoas" label="Pessoas" />
          <m-checkbox :value="2" v-model="campanha.config.pessoas" label="Empresas" />
          <m-checkbox :value="3" v-model="campanha.config.pessoas" label="Arrematantes" />
          <m-checkbox :value="4" v-model="campanha.config.pessoas" label="Comitentes" />
          <m-checkbox :value="5" v-model="campanha.config.pessoas" label="Colaboradores" />
        </div>

        <m-radio name="tipoLista" label="Para uma lista" val="lista" v-model="campanha.tipoString" />
        <div v-if="campanha.tipoString === 'lista'" class="wrapper m-t-n" style="max-width: 50%;">
          <m-radio name="tipoSublista" label="Importar lista" val="importar" v-model="campanha.config.sublista" size="sm" />
          <div v-if="campanha.config.sublista === 'importar'" class="wrapper m-t-n">
            <textarea class="sl-textarea" style="height: 500px" v-model="campanha.config.criarLista.lista" placeholder="Nome;email ou somente email. Cada contato em uma nova linha"></textarea>
            <erp-s-field class="m-t m-b" label="Digite um nome para a lista" required icon-help="Ela será salva com este nome e poderá ser utilizada posteriormente">
              <erp-input placeholder="Digite um nome para a lista" required class="sl-input bg-white" color="black" hide-underline v-model="campanha.config.criarLista.nome" />
            </erp-s-field>
          </div>

          <m-radio name="tipoSublista" label="Selecionar lista salva" val="predefinida" v-model="campanha.config.sublista" size="sm" />
          <div v-if="campanha.config.sublista === 'predefinida'" class="wrapper m-t-n">
            <lista-input v-model="campanha.config.lista" />
          </div>
        </div>
        <m-radio name="tipoLista" label="Para um leilão" val="leilao" v-model="campanha.tipoString" />
        <div v-show="campanha.tipoString === 'leilao'" class="wrapper m-t-n" style="max-width: 50%;">
          <erp-s-field class="m-t m-b" label="Selecione um leilão">
            <leilao-input v-model="campanha.config.leilao.id" />
          </erp-s-field>
          <m-checkbox value="habilitados" v-model="campanha.config.leilao.listas" label="Habilitados" />
          <m-checkbox value="lances-superados" v-model="campanha.config.leilao.listas" label="Que efetuaram lances mas não venceram" />
          <m-checkbox value="lances-vencidos" v-model="campanha.config.leilao.listas" label="Que efetuaram lances e venceram" />
          <m-checkbox value="habilitado-sem-lance" v-model="campanha.config.leilao.listas" label="Que se habilitou mas não efetuou lance" />
          <m-checkbox value="habilitado-reprovados" v-model="campanha.config.leilao.listas" label="Que se habilitou mas não foi aprovado" />
        </div>

        <m-radio name="tipoLista" label="Para um ou mais segmentos" val="segmento" v-model="campanha.tipoString" />
        <div v-show="campanha.tipoString === 'segmento'" class="wrapper m-t-n" style="max-width: 100%;">
          <div class="">
            <table class="sl-table">
              <thead>
              <tr>
                <th>Seguimento</th>
                <th>Condições</th>
                <th>Opções</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(s, key) in campanha.config.seguimentos" :key="'seguimentos-' + key">
                <td>
                  <erp-select class="sl-input" v-model="s.seguimento" :options="tiposSeguimento" />
                </td>
                <td>
                  <div class="m-l-sm m-r-sm">
                    <tipo-bem-select v-if="['tipo-bem-favorito', 'tipo-bem-lance'].includes(s.seguimento)" list-all tree no-label class="sl-select" v-model.lazy="s.config.tipoBem" />
                    <erp-s-field v-if="['bem-favorito', 'bem-lance', 'arrematante-propostas-venda-direta-bem'].includes(s.seguimento)" label="Selecione um bem">
                     <bem-input v-model="s.config.bem" />
                    </erp-s-field>
                  </div>
                </td>
                <td><a class="text-negative" @click="excluirSeguimento(s)">Excluir</a></td>
              </tr>
              </tbody>
              <tfoot>
              <td colspan="100%">
                <u-btn @click="adicionarSeguimento" label="Adicionar seguimento" class="bg-blue text-white m-t" no-caps />
              </td>
              </tfoot>
            </table>
          </div>
        </div>

<!--        <m-radio name="tipoLista" label="Para participantes de uma pesquisa" val="pesquisa" v-model="tipoLista" />-->
      </div>
      <div v-if="false" class="m-t" style="max-width: 500px">
        Fique tranquilo, ainda vamos fazer uma verificação para você saber quem desta lista selecionada não preenche os requisitos mínimos para se comunicar por este canal.
      </div>
      <div style="height: 80px"></div>
    </div>
  </transition>
</template>

<script>
import CampanhaIcone from "@/components/marketing/components/CampanhaIcone"
import MCheckbox from "@/components/marketing/components/inputs/Checkbox"
import MRadio from "@/components/marketing/components/inputs/Radio"
import {ErpInput} from "@/plugins/uloc-erp"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ListaInput from "components/marketing/components/inputs/ListaInput"
import LeilaoInput from "components/leiloes/components/window/search/LeilaoInput"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import TipoBemSelect from "components/bem/components/include/TipoSelect"
import BemInput from "components/bem/helpers/input/BemInput"
import CampanhaMixin from "components/marketing/components/campanhaMixin"

const mockSeguimento = {
  seguimento: null,
  config: {}
}

export default {
  name: "Destinatarios",
  mixins: [CampanhaMixin],
  inject: ['campanhaContainer'],
  components: {
    BemInput,
    TipoBemSelect,
    ErpSelect,
    LeilaoInput,
    ListaInput,
    ErpSField,
    MRadio,
    MCheckbox,
    CampanhaIcone,
    ErpInput
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    'campanha.config.pessoas' (v) {
      this.$nextTick(() => {
        let n = false
        this.campanha.config.pessoas.map(t => {
          if (t > 2) {
            n = true
          }
        })
        if (n) {
          this.campanha.config.pessoas.indexOf(1) !== -1 && this.campanha.config.pessoas.splice(this.campanha.config.pessoas.indexOf(1), 1)
          this.campanha.config.pessoas.indexOf(2) !== -1 && this.campanha.config.pessoas.splice(this.campanha.config.pessoas.indexOf(2), 1)
        }
      })
    }
  },
  methods: {
    adicionarSeguimento () {
      this.campanha.config.seguimentos.push(JSON.parse(JSON.stringify(mockSeguimento)))
    },
    excluirSeguimento (s) {
      this.campanha.config.seguimentos.splice(this.campanha.config.seguimentos.indexOf(s), 1)
    }
  }
}
</script>
