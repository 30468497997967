<script>
import CampanhaIcone from "@/components/marketing/components/CampanhaIcone"
import MRadio from "@/components/marketing/components/inputs/Radio"
import MCheckbox from "@/components/marketing/components/inputs/Checkbox"
import NavBar from "@/components/marketing/components/NavBar"
import Vue from "vue"
import Destinatarios from "@/components/marketing/Etapas/Destinatarios"
import Configuracao from "@/components/marketing/Etapas/Configuracao"
import Design from "@/components/marketing/Etapas/Design"
import Confirmar from "@/components/marketing/Etapas/Confirmar"
import {newCampanha, updateCampanha, find} from "@/domain/marketing/services"
import {format} from "date-fns";
import {datetimeToEn} from "@/utils/date"

Vue.component('destinatarios', Destinatarios)
Vue.component('configuracao', Configuracao)
Vue.component('design', Design)
Vue.component('confirmar', Confirmar)

const mockSeguimento = {
  seguimento: null,
  config: {}
}

export default {
  name: 'MarketingCriarCampanha2',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  provide: function () {
    return {
      campanhaContainer: this
    }
  },
  directives: {},
  components: {NavBar, MCheckbox, MRadio, CampanhaIcone},
  data() {
    return {
      component: 'destinatarios',
      templateManual: false,
      campanha: {
        id: null,
        canal: 'email',
        nome: null,
        assunto: null,
        descricao: null,
        template: '',
        templateRelacionado: null,
        dataInicio: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        status: 0,
        active: false,
        tipoString: 'todos',
        config: {
          pessoas: [1, 2],
          sublista: 'importar',
          lista: null,
          criarLista: {
            nome: null,
            lista: ''
          },
          leilao: {
            id: null,
            listas: []
          },
          seguimentos: [JSON.parse(JSON.stringify(mockSeguimento))],
        }
      }
    }
  },
  watch: {
    '$route'(r) {
      this.component = r.params.action
    },
    'templateManual' (v) {
      if (v === 'false') {
        this.templateManual = false
      }
      if (v === 'true') {
        this.campanha.templateRelacionado = null
        this.templateManual = true
      }
    }
  },
  computed: {},
  created () {
    this.component = this.$route.params.action
  },
  mounted() {
    if (this.$route.params.id && Number(this.$route.params.id) > 0) {
      this.load(this.$route.params.id)
    }
    this.erplayout.erpheader.menu.setActiveMenu('marketing')
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 400)
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load (id) {
      this.$uloc.loading.show()
      find(id)
          .then(response => {
            this.parseCampanha(response.data)
            this.$uloc.loading.hide()
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    async save () {
      return new Promise((resolve, reject) => {
        this.$uloc.loading.show({message: 'Salvando campanha'})
        let method = newCampanha
        if (this.campanha.id) {
          method = (data) => updateCampanha(this.campanha.id, data)
        }
        const data = this.prepareToSave(this.campanha)
        method(data)
            .then(response => {
              console.log(response.data)
              this.parseCampanha(response.data)
              this.$uloc.loading.hide()
              resolve(response.data)
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
              reject(error)
            })
      })
    },
    parseCampanha (data) {
      console.log(data)
      data.dataInicio = this.$options.filters.formatDate(data.dataInicio, 'dd/MM/yyyy HH:mm:ss')
      if (data.templateRelacionado) {
        this.templateManual = false
      } else {
        this.templateManual = true
      }
      if (!data.template) {
        data.template = ''
      }
      this.campanha = data
    },
    prepareToSave (d) {
      const data = JSON.parse(JSON.stringify(d))
      data.config = JSON.stringify(this.campanha.config)
      if (data.dataInicio) {
        data.dataInicio = datetimeToEn(data.dataInicio)
      }
      if (data.templateRelacionado && data.templateRelacionado.id) {
        data.templateRelacionado = data.templateRelacionado.id
      }
      if (data.remetente && data.remetente.id) {
        data.remetente = data.remetente.id
      }
      return data
    }
  },
  meta: {
    title: 'Criar Campanha - Marketing',
    name: 'Criar Campanha - Marketing'
  }
}
</script>

<template>
  <div class="mkt-module">
    <component ref="component" v-bind:is="component"/>
    <nav-bar ref="navbar" @changeComponent="(c) => component = c.id" :step="component"/>
  </div>
</template>

