export default {
  data() {
    return {
      model: this.val
    }
  },
  props: {
    value: {
      required: true
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
