<template>
  <transition name="fade" appear>
    <div class="transition-generic w-full wrapper-lg m-t-n-lg">
      <campanha-icone class="big-white m-b" :canal="$route.params.canal" />
      <e-row mr>
        <e-col>
          <erp-s-field label="Nome da campanha" required>
            <erp-input class="sl-input" v-model="campanha.nome" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Assunto" required icon-help="Para campanhas como as de e-mail, o assunto ficará no título do e-mail. Você pode usar variáveis dinâmicas">
            <erp-input class="sl-input" v-model="campanha.assunto" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <textarea v-model="campanha.descricao" class="sl-textarea" placeholder="Descrição sobre a campanha" style="height: 60px" />
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Quando iniciar a campanha?" required>
            <datetime-input class="sl-input" v-model="campanha.dataInicio" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field label="Remetente" required icon-help="Nome que será utilizado no envio">
            <remetente-input v-model="campanha.remetente" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="m-t" mr>
        <e-col>
          <p class="subtitle font-bold">Definições do layout</p>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <m-radio name="tipoLayout" label="Selecionar um layout pronto" :val="false" v-model="campanhaContainer.templateManual" size="sm" />
          <div v-if="!campanhaContainer.templateManual" class="m-b-lg e-input-modern">
            <documento-select @abrirDoc="abrirDoc" tag="marketing"
                              v-model="campanha.templateRelacionado"/>
          </div>
          <m-radio name="tipoLayout" label="Montar meu próprio layout" :val="true" v-model="campanhaContainer.templateManual" size="sm" />
        </e-col>
      </e-row>
      <div class="docs-main">
        <documento ref="docApp"/>
      </div>
    </div>
  </transition>
</template>

<script>
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import CampanhaMixin from "components/marketing/components/campanhaMixin"
import CampanhaIcone from "components/marketing/components/CampanhaIcone"
import DatetimeInput from "@/reuse/input/Datetime"
import RemetenteInput from "components/marketing/components/inputs/RemetenteInput"
import MRadio from "components/marketing/components/inputs/Radio"
import DocumentoSelect from "components/documentos/components/include/DocumentoSelect"
import Documento from "components/documentos/components/Documento"
export default {
  name: "CampanhaConfiguracao",
  mixins: [CampanhaMixin],
  components: {
    DocumentoSelect,
    MRadio,
    RemetenteInput,
    DatetimeInput,
    CampanhaIcone,
    ErpInput,
    ErpSField,
    ECol,
    ERow,
    Documento
  },
  methods: {
    abrirDoc(doc, e) {
      this.$refs.docApp.show(doc.id)
    }
  }
}
</script>
