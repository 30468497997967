<template>
  <transition v-if="campanha && campanha.id" name="fade" appear>
    <div class="transition-generic w-full wrapper-lg m-t-n-lg">
      <campanha-icone class="big-white m-b" :canal="$route.params.canal" />
      <e-row mr>
        <e-col style="width: 50%">
          <iframe ref="frame" style="width: 100%; height: 700px; border: #CCCCCC 1px solid; border-radius: 2px" :src="srcPreview"></iframe>
        </e-col>
        <e-col style="width: 50%">
          <code-editor v-model="campanha.template" :languages="[['html']]" class="source-code" width="100%" height="100%" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
        </e-col>
      </e-row>
    </div>
  </transition>
</template>

<script>
import ERow from "components/layout/components/Row"
import ECol from "components/layout/components/Col"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import CampanhaMixin from "components/marketing/components/campanhaMixin"
import CampanhaIcone from "components/marketing/components/CampanhaIcone"
import CodeEditor from "simple-code-editor"
import {upgradeCampanha} from '@/domain/marketing/services'
import {debounce, uid} from "uloc-vue"

export default {
  name: "CampanhaTema",
  mixins: [CampanhaMixin],
  data () {
    return {
      loadingPreview: false
    }
  },
  computed: {
    srcPreview () {
      return this.getUrl()
    }
  },
  watch: {
    'campanha.template' (v) {
      this.$nextTick(() => {
        this.previewTimeout()
      })
    }
  },
  components: {
    CampanhaIcone,
    // ErpInput,
    // ErpSField,
    ECol,
    ERow,
    CodeEditor
  },
  methods: {
    getUrl () {
      return `${process.env.VUE_APP_API}/api/public/mkt/campanhas/${this.campanha.id}/preview?uid=${uid()}`
    },
    previewTimeout: debounce(function () {
      this.preview()
    }, 2000),
    preview () {
      if (this.campanha && this.campanha.template) {
        this.loadingPreview = true
        upgradeCampanha(this.campanha.id, {
          templateEditCache: this.campanha.template
        })
            .then(response => {
              this.loadingPreview = false
              this.$refs.frame.src = this.getUrl()
            })
            .catch(error => {
              this.loadingPreview = false
              this.alertApiError(error)
            })
      }
    }
  }
}
</script>
