import CampanhaHelper from "components/marketing/components/campanhaHelper"
export default {
    inject: ['campanhaContainer'],
    mixins: [CampanhaHelper],
    computed: {
        campanha () {
            return this.campanhaContainer.campanha
        }
    }
}
