<template>
  <label class="i-checks block m-b-sm ng-binding ng-scope" ng-repeat="p in filtros.pessoas">
    <input type="checkbox" v-model="proxyChecked" :value="value">
    <i></i>
    {{ label }}
  </label>
</template>

<script>
import InputMixin from "./InputMixin"

export default {
  name: "MCheckbox",
  mixins: [InputMixin],
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: false
    },
    val: {
      default: null
    }
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  }
}
</script>
