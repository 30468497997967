<template>
  <helper-input-btn class="m-l-n-xs">
    <erp-input
        ref="input"
        class="sl-input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        :size="size"
        @keydown.enter="search"
        readonly
    >
      <u-popover v-if="this.findedData" hover :hover-delay="500" class="popover-user-profile" anchor="bottom middle"
                 self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          <div class="row">
            <div class="col m-r">ID: <strong>{{findedData.id}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Nome: <strong>{{findedData.nome}}</strong></div>
          </div>
          <div class="m-t">
            <e-btn label="Acessar lista" />
          </div>
        </div>
      </u-popover>
    </erp-input>
    <div class="erp-icon-float"><a @click="search"><i class="erp-icon search min"></i> </a></div>
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import search from './SearchLista'

export default {
  name: 'ListaInput',
  props: ['size'],
  mixins: [InputMixin],
  data () {
    return {
      findedData: this.findedDataObj
    }
  },
  watch: {
    findedData (v) {
      this.$emit('updateSelected', v)
    },
    findedDataObj (v) {
      this.adjust()
    }
  },
  mounted () {
    this.adjust()
  },
  components: {HelperInputBtn, UPopover},
  methods: {
    adjust () {
      if (this.findedDataObj) {
        this.description = this.findedDataObj.id + ' - ' + this.findedDataObj.nome
      }
    },
    __updateInternal () {
      this.updateInternal((v) => v.id + ' - ' + v.nome)
    },
    search: search
  }
}
</script>
