<template>
  <div class="app-navbar">
    <div class="col1">
      <u-btn @click="$router.go(-1)" no-caps color="black" class="b-radius-3px" label="Anterior" icon="chevron-left" icon-type="fa" />
    </div>
    <div class="col-center nav">
      <div v-for="(s, index) in steps" :key="s.id">
      <a @click="campanhaContainer.campanha.id && next(null, s.id)" :class="{active: s.id === step}">{{ s.label }}</a>
      <i v-if="index !== (steps.length - 1)" class="fa fa-fw fa-angle-right" />
      </div>
    </div>
    <div class="col2">
      <u-btn @click="(e) => campanhaContainer.save()" no-caps color="green" class="text-white b-radius-3px m-r" label="Salvar alterações" />
      <u-btn @click="(e) => next(e)" no-caps color="white" class="text-black b-radius-3px" :label="nextStep" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: ['step'],
  inject: ['campanhaContainer'],
  data () {
    return {
    }
  },
  computed: {
    steps () {
      return [
        {
          id: 'destinatarios',
          label: 'Destinatários'
        },
        {
          id: 'configuracao',
          label: 'Configuração'
        },
        {
          id: 'design',
          label: 'Design'
        },
        {
          id: 'confirmar',
          label: 'Confirmar'
        }
      ]
    },
    nextStep () {
      const active = this.steps.find(s => s.id === this.step)
      let index = this.steps.indexOf(active)
      index = index + 1
      if (typeof this.steps[index] === 'undefined') {
        return 'Concluir'
      }
      return 'Próxima Etapa: ' + this.steps[index].label
    }
  },
  methods: {
    async next (e, id = null) {
      console.log(id)
      const active = this.steps.find(s => s.id === this.step)
      let index = this.steps.indexOf(active)
      if (typeof id !== 'undefined' && id) {
        const next = this.steps.find(s => s.id === id)
        index = this.steps.indexOf(next)
      } else {
        index = index + 1
      }
      if (typeof this.steps[index] === 'undefined') {
        try {
          await this.campanhaContainer.save()
          this.$router.push({name: 'marketing.campanha', params: {id: this.campanhaContainer.campanha.id}})
        } catch (e) {
          return
        }
      }
      let etapa = this.steps[index]
      if (etapa.id === 'configuracao' && !this.campanhaContainer.campanha.id) {
        try {
          await this.campanhaContainer.save()
        } catch (e) {
          return
        }
      }
      this.$router.push({
        name: 'marketing.criar-campanha.canal',
        params: {
          canal: this.$route.params.canal,
          action: this.steps[index].id
        }
      })
    }
  }
}
</script>
