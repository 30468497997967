<template>
  <label :class="[classes, {'disabled': disabled}]" class="i-checks block m-b-md">
    <input :name="name" :disabled="disabled" @change="e => $emit('input', e.target.value)" :checked="value === model" type="radio" :value="val">
    <i></i>
    {{ label }}
  </label>
</template>

<script>
import InputMixin from "./InputMixin"
export default {
  name: "MRadio",
  mixins: [InputMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    val: {
      required: true
    },
    size: {
      default: 'lg'
    }
  },
  computed: {
    classes () {
      const css = []
      this.size === 'lg' && css.push('i-checks-lg text-lg')
      this.size === 'md' && css.push('i-checks-md text-md')
      this.size === 'sm' && css.push('i-checks-sm text-sm')
      return css
    }
  }
}
</script>
